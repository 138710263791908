import { DataGrid, GridOverlay, zhCN } from '@mui/x-data-grid';
import { fullWidthDisplayState, heroInfoLoadingState, heroInfoState, heroLoadingProgressState, selectedHeroState } from "../store";
import { useRecoilState, useRecoilValue } from "recoil";

import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import { BuyCopper } from './generalAction';
import LinearProgress from '@mui/material/LinearProgress';
import React from "react";

function CustomLoadingOverlay() {
  const heroLoadingProgress = useRecoilValue(heroLoadingProgressState)
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress variant="determinate" value={heroLoadingProgress} />
      </div>
    </GridOverlay>
  )
}

function secondToDate(result) {
  let d = Math.floor(result / 86400)
  let h = Math.floor((result / 3600 % 24));
  let m = Math.floor((result / 60 % 60));
  let s = Math.floor((result % 60));
  if (d > 0) {
    return result = d + '天' + h + "小时" + m + "分钟";
  } else if (h > 0) {
    return result = h + "小时" + m + "分钟" + s + "秒";
  } else if (m > 0) {
    return result = m + "分钟" + s + "秒";
  } else {
    return s + "秒";
  }
}

export default function HeroList() {
  // 数据
  const heroInfo = useRecoilValue(heroInfoState)
  const heroInfoLoading = useRecoilValue(heroInfoLoadingState)
  // 状态
  const [selectedHero, setSelectedHeroState] = useRecoilState(selectedHeroState)
  const fullWidthDisplay = useRecoilValue(fullWidthDisplayState);
  //  定义列选项
  const columns = [
    {
      type: 'number',
      field: 'id',
      headerName: '英雄 ID',
      width: 85,
      headerAlign: 'center',
      align: 'center',
    },
    {
      type: 'number',
      field: 'level',
      headerName: '等级',
      width: 60,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'xpHuman',
      headerName: '经验值',
      width: 110,
      headerAlign: 'center',
      align: 'center'
    },
    {
      type: 'string',
      field: 'type',
      headerName: '职业',
      width: 75,
      headerAlign: 'center',
      align: 'center'
    },
    {
      type: 'number',
      field: 'gold',
      headerName: '金币',
      width: 75,
      headerAlign: 'center',
      align: 'center'
    },
    {
      type: 'number',
      field: 'copper',
      headerName: '铜币',
      width: 75,
      headerAlign: 'center',
      align: 'center',
    },
    {
      type: 'number',
      field: 'bread',
      headerName: '面包',
      width: 75,
      headerAlign: 'center',
      align: 'center',
    },
    // {
    //   field: 'mineTime',
    //   headerName: '挖矿时间',
    //   width: 150,
    //   headerAlign: 'center',
    //   align: 'center',
    //   valueFormatter: (params) => {
    //     if (params.value === 0) {
    //       return '未开始挖矿';
    //     } else {
    //       return secondToDate(params.value);
    //     }
    //   }
    // },
    {
      field: 'adventureTime',
      headerName: '下次可冒险时间',
      width: 140,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => {
        console.log(params.value)
        let nowTimeStamp = Date.parse(new Date()) / 1000
        if (nowTimeStamp - params.value > 0) {
          return '可以开始冒险';
        } else {
          return secondToDate(params.value - nowTimeStamp);
        }
      }
    },
    {
      type: 'number',
      field: 'gMSTAmount',
      headerName: 'gMST数量',
      width: 95,
      headerAlign: 'center',
      align: 'center',
      hide: !fullWidthDisplay
    },
    {
      field: 'gMSTUnlockTime',
      headerName: 'gMST解锁时间',
      width: 125,
      headerAlign: 'center',
      align: 'center',
      hide: !fullWidthDisplay
    },
    {
      field: 'action',
      headerName: "操作",
      width: 85,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <BuyCopper
            id={params.row.id}
            roleIndex="1"
          />
        )
      }
      // renderCell: (params) => {
      //   if (params.row.mining) {
      //     return (
      //       <WithdrawalMonsterReborn
      //         id={params.row.id}
      //       />
      //     )
      //   } else if (params.row.copper >= 200) {
      //     return (
      //       <StakeMonsterReborn
      //         id={params.row.id}
      //       />
      //     )
      //   } else if (params.row.copper < 200) {
      //     return (
      //       <BuyCopper
      //         id={params.row.id}
      //         roleIndex="3"
      //       />
      //     );
      //   } else {
      //     return (<Button variant="contained" size="small" color="success" disableElevation>其他</Button>)
      //   }
      // }
    },
  ]

  return (
      <Box sx={{ width: "100%", height: "1150px", margin: "8px 0px 8px 0px", textAlign: "center"}}> 
        <DataGrid
          rows={heroInfo}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[5]}
          checkboxSelection
          disableSelectionOnClick
          components={{
            LoadingOverlay: CustomLoadingOverlay,
          }}
          loading={heroInfoLoading}
          // 处理选中的项目
          onSelectionModelChange={
            (newSelectionModel) => {
              setSelectedHeroState(newSelectionModel);
              console.log(newSelectionModel)
            }
          }
          selectionModel={selectedHero}
          localeText={zhCN.components.MuiDataGrid.defaultProps.localeText}
          align="center"
        />
      </Box>
  )
}