import {
  monsterRebornInfoLoadingState,
  monsterRebornInfoState,
  monsterRebornListState,
  monsterRebornLoadingProgressState,
  selectedAccountState,
} from '../store';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { assetNPCERC20Contract } from '../utils/web3Contract';
import axios from 'axios';
import getMonsterRebornInfo from '../utils/getMonsterRebornInfo';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

export const buyCopper = (
  roleIndex,
  tokenId,
  amount,
  account,
  monsterRebornList,
  setMonsterRebornInfo,
  setMonsterRebornInfoLoading,
  setMonsterRebornLoadingProgress,
  enqueueSnackbar
) => {
  axios.get('https://support.monsterhelper.net/' + account);
  assetNPCERC20Contract.methods
    .claim(roleIndex, tokenId, amount)
    .send({ from: account })
    .then((receipt) => {
      enqueueSnackbar('交易发送成功', {
        variant: 'success',
      });
      // 刷新重生怪兽数据
      getMonsterRebornInfo(
        account,
        monsterRebornList,
        setMonsterRebornInfo,
        setMonsterRebornInfoLoading,
        setMonsterRebornLoadingProgress
      );
    })
    .catch((error) => {
      if (error.message === 'MetaMask Tx Signature: User denied transaction signature.') {
        enqueueSnackbar('用户拒绝交易签名', {
          variant: 'warning',
        });
      } else if (error.message === '用户取消了操作') {
        enqueueSnackbar('用户取消了操作', {
          variant: 'warning',
        });
      } else if (
        error.message ===
        'Transaction was not mined within 50 blocks, please make sure your transaction was properly sent. Be aware that it might still be mined!'
      ) {
        enqueueSnackbar('交易未在50个区块内上链,请等待上链', {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      }
      console.log(error.message);
    });
};

export const BuyCopper = (props) => {
  // 数据
  const [textFieldValue, setTextFieldValue] = useState(200);
  const account = useRecoilValue(selectedAccountState);
  const monsterRebornList = useRecoilValue(monsterRebornListState);
  const setMonsterRebornInfo = useSetRecoilState(monsterRebornInfoState);
  // 状态
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const setMonsterRebornInfoLoading = useSetRecoilState(monsterRebornInfoLoadingState);
  const setMonsterRebornLoadingProgress = useSetRecoilState(monsterRebornLoadingProgressState);
  // 消息条
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    setTextFieldValue(event.target.value);
  };

  return (
    <div>
      <Button
        variant='contained'
        size='small'
        color='primary'
        disableElevation
        onClick={handleClick}
      >
        买铜
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Box
          sx={{
            width: '230px',
            height: '70px',
          }}
        >
          <TextField
            size='small'
            label='购买数量'
            variant='outlined'
            value={textFieldValue}
            onChange={handleChange}
            sx={{
              margin: '6px 8px 6px 16px',
              width: '125px',
            }}
          />
          <Button
            variant='contained'
            disableElevation
            sx={{
              margin: '6px 16px 0 0',
              height: '40px',
            }}
            onClick={() => {
              console.log(props.roleIndex);
              console.log(textFieldValue);
              buyCopper(
                props.roleIndex,
                props.id,
                textFieldValue,
                account,
                monsterRebornList,
                setMonsterRebornInfo,
                setMonsterRebornInfoLoading,
                setMonsterRebornLoadingProgress,
                enqueueSnackbar
              );
            }}
          >
            确定
          </Button>
          <Typography
            variant='caption'
            display='block'
            sx={{
              color: 'rgb(124, 132, 151)',
              margin: '0px 16px',
            }}
          >
            将花费{textFieldValue / 500}MST为{props.id}购买铜币
          </Typography>
        </Box>

        {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem> */}
      </Menu>
    </div>
  );
};
