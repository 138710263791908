import React, { Suspense } from "react";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ConnectWallet from '../components/ConnectWallet';
import { Link } from 'react-router-dom'
import NowGasPrice from '../NowGasPrice';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

export default function MonsterHeader() {
  return (
    <Box sx={{ flexGrow: 1, padding: "8px 0 48px 0" }}>
      <AppBar position="static" elevation={0} sx={{ backgroundColor: "white", color: "black" }}>
        <Toolbar disableGutters>
          <Typography
            variant="h4"
            noWrap
            color="primary.main"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
            Monster Hepler
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Button
              disableRipple
              sx={{
                ml: 12,
                color: 'rgb(124, 132, 151)',
                a: {
                  textDecoration: 'none',
                  color: 'rgb(124, 132, 151)',
                },
                '&:hover': {
                  backgroundColor: '#fff',
                },
                'a:hover': {
                  color: 'primary.main',
                },
              }}
            >
              <Link to='/MonsterGenesis'>
                <Typography variant="h6" underline="none">
                  创世怪兽
                </Typography>
              </Link>
            </Button>
            <Button
              disableRipple
              sx={{
                ml: 4,
                color: 'rgb(124, 132, 151)',
                a: {
                  textDecoration: 'none',
                  color: 'rgb(124, 132, 151)',
                },
                '&:hover': {
                  backgroundColor: '#fff',
                },
                'a:hover': {
                  color: 'primary.main',
                },
              }}
            >
              <Link to='/'>
                <Typography variant="h6">
                  重生怪兽
                </Typography>
              </Link>
            </Button>
            <Button
              disableRipple
              sx={{
                ml: 4,
                color: 'rgb(124, 132, 151)',
                a: {
                  textDecoration: 'none',
                  color: 'rgb(124, 132, 151)',
                },
                '&:hover': {
                  backgroundColor: '#fff',
                },
                'a:hover': {
                  color: 'primary.main',
                },
              }}
            >
              <Link to='/Hero'>
                <Typography variant="h6">
                  英雄
                </Typography>
              </Link>
            </Button>
            <Button
              disableRipple
              sx={{
                ml: 4,
                color: 'rgb(124, 132, 151)',
                a: {
                  textDecoration: 'none',
                  color: 'rgb(124, 132, 151)',
                },
                '&:hover': {
                  backgroundColor: '#fff',
                },
                'a:hover': {
                  color: 'primary.main',
                },
              }}
            >
              {/* <Link to=''> */}
                <Typography variant="h6">
                  市场
                </Typography>
              {/* </Link> */}
            </Button>
          </Box>
          <NowGasPrice />
          <Suspense fallback={<h1>Loading profile...</h1>}>
            <ConnectWallet />
          </Suspense>
        </Toolbar>
      </AppBar>
    </Box>
  );
}