import {
  dataAnalysisDisplayState,
  fullWidthDisplayState,
  mainGridState,
  monsterGenesisInfoLoadingState,
  monsterGenesisInfoState,
  monsterGenesisListState,
  monsterGenesisLoadingProgressState,
} from "../store";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import LoadMonsterGenesis from '../components/LoadMonsterGenesis';
import MonsterGenesisList from "../components/MonsterGenesisList";
import MultipleTransferNFT from '../components/MultipleTransferNFT';
import React from "react";
import Tooltip from '@mui/material/Tooltip';
import Typography from "@mui/material/Typography";
import getMonsterGenesisInfo from '../utils/getMonsterGenesisInfo';

export default function MonsterGenesis() {
  const [dataAnalysisDisplay, setDataAnalysisDisplay] = useRecoilState(dataAnalysisDisplayState);
  const [fullWidthDisplay, setFullWidthDisplay] = useRecoilState(fullWidthDisplayState);
  const [mainGrid, setMainGrid] = useRecoilState(mainGridState);
  const [monsterGenesisInfo, setMonsterGenesisInfo] = useRecoilState(monsterGenesisInfoState);
  const monsterGenesisList = useRecoilValue(monsterGenesisListState);
  const setMonsterGenesisInfoLoading = useSetRecoilState(monsterGenesisInfoLoadingState);
  const setMonsterGenesisLoadingProgress = useSetRecoilState(monsterGenesisLoadingProgressState)

  const toggleDataAnalysisDisplay = () => {
    if (fullWidthDisplay === false) {
      setDataAnalysisDisplay('none');
      setMainGrid(12);
      setFullWidthDisplay(true);
    } else {
      setDataAnalysisDisplay('block');
      setMainGrid(9);
      setFullWidthDisplay(false);
    }
  };

  // 初始化页面
  if (monsterGenesisList.length > 0 && monsterGenesisInfo.length === 0) {
    getMonsterGenesisInfo(
      monsterGenesisList,
      setMonsterGenesisInfo,
      setMonsterGenesisInfoLoading,
      setMonsterGenesisLoadingProgress
    );
  }

  return (
    <Box sx={{ padding: "8px 8px 8px 8px" }}>
      <Box sx={{ position: 'relative', padding: "48px 0 32px 0" }}>
        <IconButton
          variant="outlined"
          size="small"
          onClick={toggleDataAnalysisDisplay}
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            margin: "0 0 0 8px",
          }}
        >
          {fullWidthDisplay ? <ArrowBackIcon /> : <ArrowForwardIcon />}
        </IconButton>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          component="div"
          sx={{ margin: "auto" }}
        >
          创世怪兽
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          pt: 2,
          padding: "8px 0 8px 0",
        }}
      >
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          variant="outlined"
          size="small"
          href="https://monster.metaland.game/monster-genesis"
          target="_blank"
          sx={{ margin: "0 0 0 8px" }}
        >
          进入官网
        </Button>
        <MultipleTransferNFT role='2' />
        {/* {
          selectionModel.length
          ? <Button
              variant="outlined"
              size="small"
              onClick={multipleCopperMigration}
              sx={{ margin: "0 0 0 8px" }}
            >
              批量迁移旧铜币
            </Button>
          : <Tooltip title="请先链接钱包或等待载入钱包数据"><span><Button
              variant="outlined"
              size="small"
              disabled  
              onClick={multipleCopperMigration}
              sx={{ margin: "0 0 0 8px" }}
            >
              批量迁移旧铜币
            </Button></span></Tooltip>
        } */}
        <Tooltip title="开发中，敬请期待"><span>
          <Button variant="outlined" size="small" disabled sx={{ margin: "0 0 0 8px" }}>批量买肉</Button>
        </span></Tooltip>
        <Tooltip title="开发中，敬请期待"><span>
          <Button variant="outlined" size="small" disabled sx={{ margin: "0 0 0 8px" }}>批量喂食</Button>
        </span></Tooltip>
        <LoadMonsterGenesis />
      </Box>
      <MonsterGenesisList />
    </Box>
  );
}