import {
  heroInfoLoadingState,
  heroInfoState,
  heroListState,
  heroLoadingProgressState,
  selectedAccountState,
} from '../store';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import getHeroInfo from '../utils/getHeroInfo';

export default function LoadHero() {
  // 数据
  const selectedAccount = useRecoilValue(selectedAccountState);
  const heroList = useRecoilValue(heroListState);
  const setHeroInfo = useSetRecoilState(heroInfoState);
  // 状态
  const setHeroInfoLoading = useSetRecoilState(heroInfoLoadingState);
  const setHeroLoadingProgress = useSetRecoilState(heroLoadingProgressState);

  if (selectedAccount === undefined && heroList.length === 0) {
    return (
      <Tooltip title='请先链接钱包'>
        <div>
          <Button variant='outlined' size='small' disabled sx={{ margin: '0 0 0 8px' }}>
            刷新数据
          </Button>
        </div>
      </Tooltip>
    );
  } else {
    return (
      <Button
        variant='outlined'
        size='small'
        onClick={() => {
          getHeroInfo(
            heroList,
            setHeroInfo,
            setHeroInfoLoading,
            setHeroLoadingProgress
          );
        }}
        sx={{ margin: '0 0 0 8px' }}
      >
        刷新数据
      </Button>
    );
  }
}
