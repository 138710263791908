import {
  MultipleStakeMonsterReborn,
  MultipleWithdrawalMonsterReborn,
} from '../components/monsterRebornMultipleAction';
import {
  dataAnalysisDisplayState,
  fullWidthDisplayState,
  mainGridState,
  monsterRebornInfoLoadingState,
  monsterRebornInfoState,
  monsterRebornListState,
  monsterRebornLoadingProgressState,
  selectedAccountState,
} from '../store';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import LoadMonsterReborn from '../components/LoadMonsterReborn';
import MonsterRebornList from '../components/MonsterRebornList';
import MultipleTransferNFT from '../components/MultipleTransferNFT';
import React from 'react';
import Typography from '@mui/material/Typography';
import getMonsterRebornInfo from '../utils/getMonsterRebornInfo';

export default function MonsterGenesis() {
  const [monsterRebornList, setMonsterRebornList] = useRecoilState(monsterRebornListState);
  const [monsterRebornInfo, setMonsterRebornInfo] = useRecoilState(monsterRebornInfoState);
  const [dataAnalysisDisplay, setDataAnalysisDisplay] = useRecoilState(dataAnalysisDisplayState);
  const [fullWidthDisplay, setFullWidthDisplay] = useRecoilState(fullWidthDisplayState);
  const [mainGrid, setMainGrid] = useRecoilState(mainGridState);
  const account = useRecoilValue(selectedAccountState);
  const setMonsterRebornInfoLoading = useSetRecoilState(monsterRebornInfoLoadingState);
  const setMonsterRebornLoadingProgress = useSetRecoilState(monsterRebornLoadingProgressState);

  const toggleDataAnalysisDisplay = () => {
    if (fullWidthDisplay === false) {
      setDataAnalysisDisplay('none');
      setMainGrid(12);
      setFullWidthDisplay(true);
    } else {
      setDataAnalysisDisplay('block');
      setMainGrid(9);
      setFullWidthDisplay(false);
    }
  };

  // 初始化页面
  if (monsterRebornList.length > 0 && monsterRebornInfo.length === 0) {
    getMonsterRebornInfo(
      account,
      monsterRebornList,
      setMonsterRebornInfo,
      setMonsterRebornInfoLoading,
      setMonsterRebornLoadingProgress
    );
  }

  return (
    <Box sx={{ padding: '8px 8px 8px 8px' }}>
      <Box sx={{ position: 'relative', padding: '48px 0 32px 0' }}>
        <IconButton
          variant='outlined'
          size='small'
          onClick={toggleDataAnalysisDisplay}
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            margin: '0 0 0 8px',
          }}
        >
          {fullWidthDisplay ? <ArrowBackIcon /> : <ArrowForwardIcon />}
        </IconButton>
        <Typography
          variant='h4'
          align='center'
          gutterBottom
          component='div'
          sx={{ margin: 'auto' }}
        >
          重生怪兽
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          pt: 2,
          padding: '8px 0 8px 0',
        }}
      >
        <Box sx={{ flex: '1 1 auto' }} />
        <Button
          variant='outlined'
          size='small'
          href='https://monster.metaland.game/monster-reborn'
          target='_blank'
          sx={{ margin: '0 0 0 8px' }}
        >
          进入官网
        </Button>
        <MultipleTransferNFT role='3' />
        <MultipleStakeMonsterReborn />
        <MultipleWithdrawalMonsterReborn />
        <LoadMonsterReborn />
      </Box>
      <MonsterRebornList />
    </Box>
  );
}
