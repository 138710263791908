import { mineAddress, monsterRebornAddress } from './web3Contract';

import axios from 'axios';
import pull from 'lodash/pull';

const getMonsterRebornList =  async (account) => {
  let _monsterRebornList = [];
  const result = await axios.get(
    'https://api.ftmscan.com/api', {
      params: {
        module: 'account',
        action: 'tokennfttx',
        contractaddress: monsterRebornAddress,
        address: account,
        sort: 'asc',
        apikey: 'CYDU7M6EB3E18JBE9X1256Q8396ENGRPV4'
      }
    }
  );
  // console.log(result.data.result)
  for (let i of result.data.result) {
    if (i.to === account.toLowerCase() && i.from !== mineAddress.toLowerCase()) {
      _monsterRebornList.push(i.tokenID);
    } else if (i.to !== mineAddress.toLowerCase() && i.to !== account.toLowerCase()) {
      pull(_monsterRebornList, i.tokenID);
    }
  }
  // setMonsterRebornList(_monsterRebornList);
  console.log(_monsterRebornList)
  return(_monsterRebornList);
};

export default getMonsterRebornList;