import { mine3InfoContract, monsterRebornInfoContract } from './web3Contract'

import cutArray from '../utils/cutArray';

const handleMonsterRebornInfo = (monsterRebornUintInfo, monsterRebornIntInfo, monsterRebornStringInfo, monsterRebornBoolInfo, mineUintInfo, monsterRebornListLength, setMonsterRebornInfo) => {
  let monsterRebornInfo = [];
  let monsterRebornType = [
    '畸变', '动物', '构造', '龙', '元素', '妖怪', '巨人', '类人',
    '魔兽', '半兽人', '泥型怪', '异界', '植物', '亡灵', '昆虫',
  ]
  for (let i = 0; i < monsterRebornListLength; ++i) {
    let ishatched = monsterRebornBoolInfo[i][0];
    if (ishatched) {
      // 处理name
      let name = '';
      let name_list = monsterRebornStringInfo[i][0].split(', ');
      if (name_list.length > 1) {
        name = name_list[1] + ' ' + name_list[0];
      } else {
        name = name_list[0];
      }
      // 处理mineTime
      // let mineTime = 0;
      // if (monsterRebornUintInfo[i][13] !== '0') {
      //   mineTime = Date.parse(new Date()) / 1000 - monsterRebornUintInfo[i][13]
      // }
      // 处理mining
      let mining;
      if (mineUintInfo[i][3] === '1') {
        mining = true;
      } else {
        mining = false;
      }
      // 处理minedCopper和miningBonus
      let minedCopper = null;
      if (mining) {
        minedCopper = mineUintInfo[i][1]
      }
      monsterRebornInfo.push({
        id: monsterRebornUintInfo[i][0],
        cr: monsterRebornUintInfo[i][1],
        name: name,
        type: monsterRebornType[monsterRebornUintInfo[i][2] - 1],
        size: monsterRebornUintInfo[i][3],
        hp: monsterRebornUintInfo[i][4],
        strength: monsterRebornUintInfo[i][5],
        dexterity: monsterRebornUintInfo[i][6],
        constitution: monsterRebornUintInfo[i][7],
        intelligence: monsterRebornUintInfo[i][8],
        wisdom: monsterRebornUintInfo[i][9],
        charisma: monsterRebornUintInfo[i][10],
        ability: `${monsterRebornUintInfo[i][4]} ${monsterRebornUintInfo[i][5]} ${monsterRebornUintInfo[i][6]} ${monsterRebornUintInfo[i][7]} ${monsterRebornUintInfo[i][8]} ${monsterRebornUintInfo[i][9]} ${monsterRebornUintInfo[i][10]}`,
        copper: monsterRebornUintInfo[i][11],
        meat: monsterRebornUintInfo[i][12],
        // mineTime: mineTime,
        mineEfficiency: mineUintInfo[i][2],
        minedCopper: minedCopper,
        from: monsterRebornUintInfo[i][15],
        generation: monsterRebornUintInfo[i][16],
        emergingTS: monsterRebornUintInfo[i][17],
        value: monsterRebornUintInfo[i][18],
        mining: mining,
        isApprovedForAll: monsterRebornBoolInfo[i][2],
      });
    }
  }
  setMonsterRebornInfo(monsterRebornInfo)
};

const getMonsterRebornInfo = async (account, monsterRebornList, setMonsterRebornInfo, setMonsterRebornInfoLoading, setMonsterRebornLoadingProgress) => {
  setMonsterRebornInfoLoading(true);
  setMonsterRebornLoadingProgress(0)
  let uintInfo = [], intInfo = [], stringInfo = [], boolInfo = [];
  let mine3UintInfo = [];
  // 分割原数组
  let monsterRebornListCut = cutArray(monsterRebornList, 30);
  // 查询链上信息
  // 查询基础信息
  for (let i = 0; i < monsterRebornListCut.length; i++) {
    let res;
    try {
      res = await monsterRebornInfoContract.methods
      .getMonsterRebornInfo(monsterRebornListCut[i], account, '0x0000000000000000000000000000000000000000')
      .call();
    } catch (error) {
      console.log(error);
      res = await monsterRebornInfoContract.methods
      .getMonsterRebornInfo(monsterRebornListCut[i], account, '0x0000000000000000000000000000000000000000')
      .call();
    }
    uintInfo = [...uintInfo, ...cutArray(res['0'], 19)];
    intInfo = [...intInfo, ...cutArray(res['1'], 1)];
    stringInfo = [...stringInfo, ...cutArray(res['2'], 1)];
    boolInfo = [...boolInfo, ...cutArray(res['3'], 3)];
    setMonsterRebornLoadingProgress((45 / monsterRebornListCut.length) * (i + 1));
  }
  // 查询挖矿信息
  for (let i = 0; i < monsterRebornListCut.length; i++) {
    let res;
    try {
      res = await mine3InfoContract.methods
      .getMine3Info(monsterRebornListCut[i])
      .call();
    } catch (error) {
      console.log(error);
      res = await mine3InfoContract.methods
      .getMine3Info(monsterRebornListCut[i])
      .call();
    }
    mine3UintInfo = [...mine3UintInfo, ...cutArray(res, 4)];
    setMonsterRebornLoadingProgress((45 / monsterRebornListCut.length) * (i + 1) + 45);
  }
  setMonsterRebornLoadingProgress(90);
  handleMonsterRebornInfo(uintInfo, intInfo, stringInfo, boolInfo, mine3UintInfo, monsterRebornList.length, setMonsterRebornInfo);
  setMonsterRebornLoadingProgress(100);
  setMonsterRebornInfoLoading(false);
};

export default getMonsterRebornInfo;
