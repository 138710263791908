import {
  heroContract,
  heroExecuteAddress,
  heroExecuteContract,
} from '../../../utils/web3Contract';
import { heroInfoState, selectedAccountState, selectedHeroState } from '../../../store';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import cutArray from '../../../utils/cutArray';
import getHeroInfo from '../../../utils/getHeroInfo'
import { useRecoilValue } from 'recoil';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { web3 } from '../../ConnectWallet';

export default function MultipleAdventure() {
  // 数据
  const [approval, setApproval] = useState(false);
  const heroInfo = useRecoilValue(heroInfoState);
  const account = useRecoilValue(selectedAccountState);
  const selectedHero = useRecoilValue(selectedHeroState);
  let needAdventure;
  // 状态
  const [executeAlertOpen, setExecuteAlertOpen] = useState(false);
  const [adventureAlertOpen, setAdventureAlertOpen] = useState(false);
  // 消息条
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // 授权检测
  const approveCheck = async () => {
    let res = await heroContract.methods.isApprovedForAll(account, heroExecuteAddress).call();
    console.log(res);
    setApproval(res);
  };

  // 执行检测
  const handleExecuteAlertClose = () => {
    setExecuteAlertOpen(false);
  };

  const showExecuteAlert = () => {
    if (selectedHero.length > needAdventure.length) {
      setExecuteAlertOpen(true);
    } else {
      setAdventureAlertOpen(true);
    }
  };

  // 冒险警告
  const handleAdventureAlertClose = () => {
    setAdventureAlertOpen(false);
  };

  // 授权
  const approve = async () => {
    heroContract.methods
      .setApprovalForAll(heroExecuteAddress, true)
      .send({ from: account })
      .then((receipt) => {
        enqueueSnackbar('交易发送成功', {
          variant: 'success',
        });
        // 重新检测授权
        approveCheck();
      })
      .catch((error) => {
        if (error.message === 'MetaMask Tx Signature: User denied transaction signature.') {
          enqueueSnackbar('用户拒绝交易签名', {
            variant: 'warning',
          });
        } else if (error.message === '用户取消了操作') {
          enqueueSnackbar('用户取消了操作', {
            variant: 'warning',
          });
        } else if (
          error.message ===
          'Transaction was not mined within 50 blocks, please make sure your transaction was properly sent. Be aware that it might still be mined!'
        ) {
          enqueueSnackbar('交易未在50个区块内上链,请等待上链', {
            variant: 'warning',
          });
        } else {
          enqueueSnackbar(error.message, {
            variant: 'error',
          });
        }
        console.log(error.message);
      });
  };

  // 冒险
  const adventure = async () => {
    let gasPrice = parseInt((await web3.eth.getGasPrice()) * 0.8);
    if (gasPrice < 100 * 10 ** 9) {
      gasPrice = 100 * 10 ** 9;
    }
    // 分割原数组
    let needAdventureCut = cutArray(needAdventure, 100);
    const key = enqueueSnackbar('等待本地模拟以获取最优Gas参数...', {
      variant: 'info',
      persist: true,
    });
    for (let i = 0; i < needAdventureCut.length; i++) {
      let gasLimit = await heroExecuteContract.methods
        .multipleAdventure(needAdventureCut[i])
        .estimateGas({ from: account })
      closeSnackbar(key);
      heroExecuteContract.methods
        .multipleAdventure(needAdventureCut[i])
        .send({ from: account, gasPrice: gasPrice, gas: gasLimit })
        .then((receipt) => {
          enqueueSnackbar('交易发送成功', {
            variant: 'success',
          });
          // getHeroInfo(
          //   heroList,
          //   setHeroInfo,
          //   setHeroInfoLoading,
          //   setHeroLoadingProgress
          // );
        })
        .catch((error) => {
          if (error.message === 'MetaMask Tx Signature: User denied transaction signature.') {
            enqueueSnackbar('用户拒绝交易签名', {
              variant: 'warning',
              preventDuplicate: true,
            });
          } else if (error.message === '用户取消了操作') {
            enqueueSnackbar('用户取消了操作', {
              variant: 'warning',
              preventDuplicate: true,
            });
          } else if (
            error.message ===
            'Transaction was not mined within 50 blocks, please make sure your transaction was properly sent. Be aware that it might still be mined!'
          ) {
            enqueueSnackbar('交易未在50个区块内上链,请等待上链', {
              variant: 'warning',
              preventDuplicate: true,
            });
          } else {
            enqueueSnackbar(error.message, {
              variant: 'error',
              preventDuplicate: true,
            });
          }
          console.log(error.message);
        });
    }
  };

  if (account === undefined || selectedHero.length === 0) {
    return (
      <Tooltip title='请先选择所需执行的目标'>
        <div>
          <Button variant='outlined' size='small' disabled sx={{ margin: '0 0 0 8px' }}>
            批量冒险
          </Button>
        </div>
      </Tooltip>
    );
  } else {
    // 授权检测
    approveCheck();
    // 冒险前置条件筛选
    let canAdventure = [];
    // 筛选未到冷却冒险时间的英雄
    //  处理时区
    // let timeZoneOffset = -new Date().getTimezoneOffset() * 60 * 1000;
    let nowTimeStamp = Date.parse(new Date()) / 1000;
    for (let i of heroInfo) {
      if (i['adventureTime'] <= nowTimeStamp) {
        canAdventure.push(i['id']);
      }
    }
    needAdventure = selectedHero.filter((x) => canAdventure.includes(x));

    return (
      <div>
        <Button
          variant='outlined'
          size='small'
          onClick={() => {
            showExecuteAlert();
          }}
          sx={{ margin: '0 0 0 8px' }}
        >
          批量冒险
        </Button>
        <Dialog open={executeAlertOpen} onClose={handleExecuteAlertClose}>
          <DialogTitle>执行检测</DialogTitle>
          <DialogContent>
            <DialogContentText>
              检测到选择的目标中有{selectedHero.length - needAdventure.length}
              个不符合冒险的前置条件(未到冷却时间)，是否继续提交符合条件的
              {needAdventure.length}个目标？
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleExecuteAlertClose}>返回</Button>
            <Button
              autoFocus
              onClick={() => {
                handleExecuteAlertClose();
                setAdventureAlertOpen(true);
              }}
            >
              确定
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={adventureAlertOpen} onClose={handleAdventureAlertClose}>
          <DialogTitle>批量冒险</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Box
                sx={{
                  width: '500px',
                }}
              >
                <Typography
                  variant='body1'
                  sx={{
                    padding: 0,
                    margin: '8px 0px 8px 0px',
                  }}
                >
                  开始冒险{needAdventure.length}个英雄，将分{cutArray(needAdventure, 100).length}
                  个交易执行，请依次确认交易。
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {approval ? (
              <Button
                variant='contained'
                onClick={() => {
                  adventure();
                  handleAdventureAlertClose();
                }}
                sx={{
                  margin: '0px 16px 16px 16px',
                }}
              >
                确定
              </Button>
            ) : (
              <Button
                variant='contained'
                onClick={approve}
                sx={{
                  margin: '0px 16px 16px 16px',
                }}
              >
                授权英雄执行合约
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
