import { HeroInfoContract } from './web3Contract'
import Web3 from 'web3';
import cutArray from './cutArray';

var BN = Web3.utils.BN;

const handleHeroInfo = (info, heroListLength, setHeroInfo) => {
  let heroInfo = [];
  let heroType = [
    '野蛮人', '吟游诗人', '牧师', '德鲁伊', '战士', '武僧',
    '圣武士', '巡林客', '游荡者', '术士', '法师'
  ]
  for (let i = 0; i < heroListLength; ++i) {
    //  处理时区
    // let timeZoneOffset = - new Date().getTimezoneOffset() * 60 * 1000;
    // 处理小数点
    let decimals = new BN(String(10**18));
    let xp = new BN(info[i][1]).div(decimals);
    let xpToNextLevel = info[i][4] * 1000;
    for (let j = 1; j < info[i][4]; j++) {
      xpToNextLevel += j * 1000;
    }
    let xpHuman = `${xp} / ${xpToNextLevel}`
    let gold = new BN(info[i][11]).div(decimals);
    heroInfo.push({
      id: info[i][0],
      xp: xp,
      xpHuman: xpHuman,
      level: info[i][4],
      type: heroType[info[i][3] - 1],
      strength: info[i][5],
      dexterity: info[i][6],
      constitution: info[i][7],
      intelligence: info[i][8],
      wisdom: info[i][9],
      charisma: info[i][10],
      adventureTime: info[i][2],
      gold: gold,
      copper: info[i][12],
      bread: info[i][13],
      gMSTAmount: info[i][14],
      gMSTLockedAt: info[i][15],
      gMSTlockDuration: info[i][16],
      gMSTUnlockTime: Number(info[i][15]) + Number(info[i][16])
    });
  }
  setHeroInfo(heroInfo)
};

const getHeroInfo = async (heroList, setHeroInfo, setHeroInfoLoading, setHeroLoadingProgress) => {
  setHeroInfoLoading(true);
  setHeroLoadingProgress(0)
  let info = [];
  // 分割原数组
  let heroListCut = cutArray(heroList, 100);
  // 查询链上信息
  for (let i = 0; i < heroListCut.length; i++) {
    let res = await HeroInfoContract.methods
      .getHeroInfo(heroListCut[i])
      .call();
      info = [...info, ...cutArray(res, 17)];
    setHeroLoadingProgress((90 / heroListCut.length) * (i + 1));
  }
  setHeroLoadingProgress(90);
  handleHeroInfo(info, heroList.length, setHeroInfo);
  setHeroLoadingProgress(100);
  setHeroInfoLoading(false);
};

export default getHeroInfo;
