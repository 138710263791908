import { DataGrid, GridOverlay, zhCN } from '@mui/x-data-grid';
import { StakeMonsterReborn, WithdrawalMonsterReborn } from './monsterRebornAction';
import { fullWidthDisplayState, monsterRebornInfoLoadingState, monsterRebornInfoState, monsterRebornLoadingProgressState, selectedMonsterRebornState } from "../store";
import { useRecoilState, useRecoilValue } from "recoil";

import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import { BuyCopper } from './generalAction';
import LinearProgress from '@mui/material/LinearProgress';
import React from "react";

function CustomLoadingOverlay() {
  const monsterRebornLoadingProgress = useRecoilValue(monsterRebornLoadingProgressState)
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress variant="determinate" value={monsterRebornLoadingProgress} />
      </div>
    </GridOverlay>
  )
}

function secondToDate(result) {
  let d = Math.floor(result / 86400)
  let h = Math.floor((result / 3600 % 24));
  let m = Math.floor((result / 60 % 60));
  let s = Math.floor((result % 60));
  if (d > 0) {
    return result = d + '天' + h + "小时" + m + "分钟";
  } else if (h > 0) {
    return result = h + "小时" + m + "分钟" + s + "秒";
  } else if (m > 0) {
    return result = m + "分钟" + s + "秒";
  } else {
    return s + "秒";
  }
}

export default function MonsterRebornList() {
  // 数据
  const monsterRebornInfo = useRecoilValue(monsterRebornInfoState)
  const monsterRebornInfoLoading = useRecoilValue(monsterRebornInfoLoadingState)
  // 状态
  const [selectedMonsterReborn, setSelectedMonsterRebornState] = useRecoilState(selectedMonsterRebornState)
  const fullWidthDisplay = useRecoilValue(fullWidthDisplayState);
  //  定义列选项
  const columns = [
    {
      type: 'number',
      field: 'id',
      headerName: '怪兽 ID',
      width: 80,
      headerAlign: 'center',
      align: 'center',
    },
    {
      type: 'number',
      field: 'cr',
      headerName: 'CR',
      width: 80,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => {
        let crList = [
          '1/10', '1/8', '1/6', '1/4', '1/3', '1/2', '1', '2', '3', '4',
          '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15',
          '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27'
        ]
        return(crList[params.value - 1])
      },
    },
    {
      type: 'string',
      field: 'name',
      headerName: '名称',
      width: 210,
      headerAlign: 'center',
      align: 'center',
      hide: !fullWidthDisplay,
    },
    {
      type: 'string',
      field: 'type',
      headerName: '种族',
      width: 85,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'size',
      headerName: '体型',
      width: 85,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => {
        let sizeList = [
          'Fine', 'Diminutive', 'Tiny', 'Small', 'Medium', 'Large', 'Huge', 'Gargantuan', 'Colossal'
        ]
        return(sizeList[params.value - 1])
      },
    },
    {
      type: 'number',
      field: 'copper',
      headerName: '铜币',
      width: 80,
      headerAlign: 'center',
      align: 'center',
    },
    {
      type: 'number',
      field: 'meat',
      headerName: '肉块',
      width: 80,
      headerAlign: 'center',
      align: 'center',
    },
    {
      type: 'number',
      field: 'mineEfficiency',
      headerName: '挖矿效率',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => {
        if (params.value !== '0') {
          return params.value + ' / 每天'
        } else {
          return '无'
        }
      },
    },
    {
      type: 'number',
      field: 'minedCopper',
      headerName: '挖矿收获',
      width: 85,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => {
        if (params.value) {
          return params.value
        } else {
          return '无'
        }
      }
    },
    {
      field: 'action',
      headerName: "操作",
      width: 80,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        if (params.row.mining) {
          return (
            <WithdrawalMonsterReborn
              id={params.row.id}
            />
          )
        } else if (params.row.copper >= 200) {
          return (
            <StakeMonsterReborn
              id={params.row.id}
            />
          )
        } else if (params.row.copper < 200) {
          return (
            <BuyCopper
              id={params.row.id}
              roleIndex="3"
            />
          );
        } else {
          return (<Button variant="contained" size="small" color="success" disableElevation>其他</Button>)
        }
        
      }
    },
  ]

  return (
      <Box sx={{ width: "100%", height: "1150px", margin: "8px 0px 8px 0px", textAlign: "center"}}> 
        <DataGrid
          rows={monsterRebornInfo}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[5]}
          checkboxSelection
          disableSelectionOnClick
          components={{
            LoadingOverlay: CustomLoadingOverlay,
          }}
          loading={monsterRebornInfoLoading}
          // 处理选中的项目
          onSelectionModelChange={
            (newSelectionModel) => {
              setSelectedMonsterRebornState(newSelectionModel);
              console.log(newSelectionModel)
            }
          }
          selectionModel={selectedMonsterReborn}
          localeText={zhCN.components.MuiDataGrid.defaultProps.localeText}
          align="center"
        />
      </Box>
  )
}