import Button from "@mui/material/Button";
import React from "react";
import { nowGasPriceState } from "./store";
import { useInterval } from "./utils";
import { useRecoilState } from "recoil";
import { web3 } from './components/ConnectWallet'

export default function NowGasPrice() {
  const [nowGasPrice, setNowGasPriceState] = useRecoilState(nowGasPriceState);

  

  const handleNowGasPrice = async () => {
    await web3.eth.getGasPrice().then((res) => {
      let _nowGasPrice = web3.utils.fromWei(res, 'gwei').split('.')[0];
      // console.log(_nowGasPrice);
      if (_nowGasPrice !== nowGasPrice) {
        setNowGasPriceState(_nowGasPrice);
      }
    })
  }
  if (nowGasPrice === 0) {
    handleNowGasPrice();
  }

  // 设定定时器
  useInterval(() => {
    handleNowGasPrice();
  }, 5000)

  return (
    <Button variant="text" sx={{ margin: "0px 8px 0 8px" }}>{nowGasPrice} gwei</Button>
  );
}
