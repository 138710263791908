import { dataAnalysisDisplayState, heroCountState, monsterGenesisCopperState, monsterGenesisCountState, monsterRebornCopperState, monsterRebornCountState, totalCopperWorldState } from '../store';
import { useRecoilState, useRecoilValue } from 'recoil';

import Box from '@mui/material/Box';
import React from "react";
import Typography from '@mui/material/Typography';

// import { copperBoxContract } from "./w3";

export default function DataAnalysis() {
  const monsterGenesisCount = useRecoilValue(monsterGenesisCountState);
  const monsterGenesisCopper = useRecoilValue(monsterGenesisCopperState);
  const monsterRebornCount = useRecoilValue(monsterRebornCountState);
  const monsterRebornCopper = useRecoilValue(monsterRebornCopperState);
  const heroCount = useRecoilValue(heroCountState);

  // const totalAdventure = useRecoilValue(totalAdventureState);
  // const totalWins = useRecoilValue(totalWinsState);
  // const [totalCopperWorld, setTotalCopperWorld] = useRecoilState(totalCopperWorldState);
  // const [totalCopperWorldOfSummoner, setTotalCopperWorldOfSummoner] = useRecoilState(
  //   totalCopperWorldOfSummonerState
  // );
  // const BN = require("bn.js");
  // const decimals = new BN(String(10 ** 18));

  // const getWorldInfo = async() => {
  //   const _totalCopperWorld = new BN(
  //    await copperBoxContract.methods.totalSupply().call()
  //   )
  //     .div(decimals)
  //     .toNumber();
  //   const _totalCopperWorldOfSummoner = new BN(
  //    await copperBoxContract.methods.totalSupplyOfSummoner().call()
  //   )
  //     .div(decimals)
  //     .toNumber();
  //   setTotalCopperWorld(_totalCopperWorld);
  //   setTotalCopperWorldOfSummoner(_totalCopperWorldOfSummoner);
  // }
  
  // setInterval(getWorldInfo, 1000);

  return(
    <Box sx={{ padding: "48px 16px 8px 16px" }}>
      <Box sx={{ padding: "0 0 48px 0" }}>
        <Typography variant='h5' gutterBottom component="div" sx= {{ margin: "auto", padding: "8px 0 32px 0" }}>数据统计</Typography>
        {/* <Typography variant='h8' gutterBottom component="div" sx= {{margin: "auto"}}>开发中...</Typography> */}
        <Box sx={{display: 'flex', flexDirection: 'row', pt: 2, padding: "8px 0 8px 0"}}>
          <Typography variant='h8' gutterBottom component="div" sx= {{margin: "auto"}}>创世怪兽:</Typography>
          <Box sx={{ flex: '1 1 auto' }} />
          <Typography variant='h8' gutterBottom component="div" sx= {{margin: "auto"}}>{monsterGenesisCount}</Typography>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'row', pt: 2, padding: "8px 0 8px 0"}}>
          <Typography variant='h8' gutterBottom component="div" sx= {{margin: "auto"}}>重生怪兽:</Typography>
          <Box sx={{ flex: '1 1 auto' }} />
          <Typography variant='h8' gutterBottom component="div" sx= {{margin: "auto"}}>{monsterRebornCount}</Typography>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'row', pt: 2, padding: "8px 0 8px 0"}}>
          <Typography variant='h8' gutterBottom component="div" sx= {{margin: "auto"}}>英雄</Typography>
          <Box sx={{ flex: '1 1 auto' }} />
          <Typography variant='h8' gutterBottom component="div" sx= {{margin: "auto"}}>{heroCount}</Typography>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'row', pt: 2, padding: "8px 0 8px 0"}}>
          <Typography variant='h8' gutterBottom component="div" sx= {{margin: "auto"}}>创世怪兽铜币:</Typography>
          <Box sx={{ flex: '1 1 auto' }} />
          <Typography variant='h8' gutterBottom component="div" sx= {{margin: "auto"}}>{monsterGenesisCopper}</Typography>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'row', pt: 2, padding: "8px 0 8px 0"}}>
          <Typography variant='h8' gutterBottom component="div" sx= {{margin: "auto"}}>重生怪兽铜币:</Typography>
          <Box sx={{ flex: '1 1 auto' }} />
          <Typography variant='h8' gutterBottom component="div" sx= {{margin: "auto"}}>{monsterRebornCopper}</Typography>
        </Box>
      </Box>
      <Box>
        {/* <Typography variant='h5' gutterBottom component="div" sx= {{ margin: "auto", padding: "8px 0 32px 0" }}>全球数据</Typography>
        <Typography variant='h8' gutterBottom component="div" sx= {{margin: "auto"}}>开发中...</Typography> */}
        {/* <Box sx={{display: 'flex', flexDirection: 'row', pt: 2, padding: "8px 0 8px 0"}}>
          <Typography variant='h8' gutterBottom component="div" sx= {{margin: "auto"}}>胜率：</Typography>
          <Box sx={{ flex: '1 1 auto' }} />
          <Typography variant='h8' gutterBottom component="div" sx= {{margin: "auto"}}>{Math.round(totalCopperWorldOfSummoner / totalCopperWorld * 10000) / 100 + '%'}</Typography>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'row', pt: 2, padding: "8px 0 8px 0"}}>
          <Typography variant='h8' gutterBottom component="div" sx= {{margin: "auto"}}>总铜币：</Typography>
          <Box sx={{ flex: '1 1 auto' }} />
          <Typography variant='h8' gutterBottom component="div" sx= {{margin: "auto"}}>{totalCopperWorld}</Typography>
        </Box> */}
      </Box>
    </Box>
  );
}