import {
  monsterRebornInfoLoadingState,
  monsterRebornInfoState,
  monsterRebornListState,
  monsterRebornLoadingProgressState,
  selectedAccountState,
  selectedMonsterRebornState,
} from '../store';
import { stakeMonsterReborn, withdrawalMonsterReborn } from './monsterRebornAction';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

export const MultipleStakeMonsterReborn = () => {
  // 数据
  const account = useRecoilValue(selectedAccountState);
  const monsterRebornList = useRecoilValue(monsterRebornListState);
  const [monsterRebornInfo, setMonsterRebornInfo] = useRecoilState(monsterRebornInfoState);
  // 状态
  const [alertOpen, setAlertOpen] = useState(false);
  const selectedMonsterReborn = useRecoilValue(selectedMonsterRebornState);
  const setMonsterRebornInfoLoading = useSetRecoilState(monsterRebornInfoLoadingState);
  const setMonsterRebornLoadingProgress = useSetRecoilState(monsterRebornLoadingProgressState);
  // 消息条
  const { enqueueSnackbar } = useSnackbar();

  const multipleStakeMonsterReborn = () => {
    for (let i of needStakeMonsterReborn) {
      stakeMonsterReborn(
        3,
        i,
        account,
        monsterRebornList,
        setMonsterRebornInfo,
        setMonsterRebornInfoLoading,
        setMonsterRebornLoadingProgress,
        enqueueSnackbar
      );
    }
  };

  const handleClose = () => {
    setAlertOpen(false);
  };

  const showStakeAlert = () => {
    if (selectedMonsterReborn.length > needStakeMonsterReborn.length) {
      setAlertOpen(true);
    } else {
      multipleStakeMonsterReborn();
    }
  };

  // 挖矿前置条件筛选
  let canStake = [];
  for (let i of monsterRebornInfo) {
    if (i['copper'] >= 200 && !i['mining']) {
      canStake.push(i['id']);
    }
  }
  let needStakeMonsterReborn = selectedMonsterReborn.filter((x) => canStake.includes(x));

  if (needStakeMonsterReborn.length > 0) {
    return (
      <div>
        <Button
          variant='outlined'
          size='small'
          onClick={showStakeAlert}
          sx={{ margin: '0 0 0 8px' }}
        >
          批量挖矿
        </Button>
        <Dialog open={alertOpen} onClose={handleClose}>
          <DialogTitle>执行检测</DialogTitle>
          <DialogContent>
            <DialogContentText>
              检测到选择的怪兽中有{selectedMonsterReborn.length - needStakeMonsterReborn.length}
              个不符合挖矿的前置条件（怪兽需有200铜币作为门票且未在挖矿），是否继续提交符合条件的
              {needStakeMonsterReborn.length}个怪兽？
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>返回</Button>
            <Button
              autoFocus
              onClick={() => {
                handleClose();
                multipleStakeMonsterReborn();
              }}
            >
              确定
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else {
    return (
      <Tooltip title='请先选择所需执行的怪兽'>
        <span>
          <Button variant='outlined' size='small' disabled sx={{ margin: '0 0 0 8px' }}>
            批量挖矿
          </Button>
        </span>
      </Tooltip>
    );
  }
};

export const MultipleWithdrawalMonsterReborn = () => {
  // 数据
  const account = useRecoilValue(selectedAccountState);
  const monsterRebornList = useRecoilValue(monsterRebornListState);
  const [monsterRebornInfo, setMonsterRebornInfo] = useRecoilState(monsterRebornInfoState);
  // 状态
  const [alertOpen, setAlertOpen] = useState(false);
  const selectedMonsterReborn = useRecoilValue(selectedMonsterRebornState);
  const setMonsterRebornInfoLoading = useSetRecoilState(monsterRebornInfoLoadingState);
  const setMonsterRebornLoadingProgress = useSetRecoilState(monsterRebornLoadingProgressState);
  // 消息条
  const { enqueueSnackbar } = useSnackbar();

  const multipleWithdrawalMonsterReborn = () => {
    for (let i of needWithdrawalMonsterReborn) {
      withdrawalMonsterReborn(
        3,
        i,
        account,
        monsterRebornList,
        setMonsterRebornInfo,
        setMonsterRebornInfoLoading,
        setMonsterRebornLoadingProgress,
        enqueueSnackbar
      );
    }
  };

  const handleClose = () => {
    setAlertOpen(false);
  };

  const showWithdrawalAlert = () => {
    if (selectedMonsterReborn.length > needWithdrawalMonsterReborn.length) {
      setAlertOpen(true);
    } else {
      multipleWithdrawalMonsterReborn();
    }
  };

  // 挖矿前置条件筛选
  let canWithdrawal = [];
  for (let i of monsterRebornInfo) {
    if (i['mining']) {
      canWithdrawal.push(i['id']);
    }
  }
  let needWithdrawalMonsterReborn = selectedMonsterReborn.filter((x) => canWithdrawal.includes(x));

  if (needWithdrawalMonsterReborn.length > 0) {
    return (
      <div>
        <Button
          variant='outlined'
          size='small'
          onClick={showWithdrawalAlert}
          sx={{ margin: '0 0 0 8px' }}
        >
          批量收矿
        </Button>
        <Dialog open={alertOpen} onClose={handleClose}>
          <DialogTitle>执行检测</DialogTitle>
          <DialogContent>
            <DialogContentText>
              检测到选择的怪兽中有
              {selectedMonsterReborn.length - needWithdrawalMonsterReborn.length}
              个不符合收矿的前置条件（怪兽需在挖矿），是否继续提交符合条件的
              {needWithdrawalMonsterReborn.length}个怪兽？
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>返回</Button>
            <Button
              autoFocus
              onClick={() => {
                handleClose();
                multipleWithdrawalMonsterReborn();
              }}
            >
              确定
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else {
    return (
      <Tooltip title='请先选择所需执行的怪兽'>
        <span>
          <Button variant='outlined' size='small' disabled sx={{ margin: '0 0 0 8px' }}>
            批量收矿
          </Button>
        </span>
      </Tooltip>
    );
  }
};
