import axios from 'axios';
import { heroAddress } from './web3Contract';
import pull from 'lodash/pull';

const getHeroList =  async (account) => {
  let _heroList = [];
  const result = await axios.get(
    'https://api.ftmscan.com/api', {
      params: {
        module: 'account',
        action: 'tokennfttx',
        contractaddress: heroAddress,
        address: account,
        sort: 'asc',
        apikey: 'CYDU7M6EB3E18JBE9X1256Q8396ENGRPV4'
      }
    }
  );
  console.log(result.data.result)
  for (let i of result.data.result) {
    if (i.to === account.toLowerCase()) {
      _heroList.push(i.tokenID);
    } else if (i.from === account.toLowerCase()) {
      pull(_heroList, i.tokenID);
    }
  }
  // setMonsterRebornList(_monsterRebornList);
  console.log(_heroList)
  return(_heroList);
};

export default getHeroList;