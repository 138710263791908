import { heroContract, monsterGenesisContract, monsterHelperExecuteAddress, monsterHelperExecuteContract, monsterRebornContract } from '../utils/web3Contract';
import {
  heroInfoState,
  monsterGenesisInfoState,
  monsterRebornInfoState,
  selectedAccountState,
  selectedHeroState,
  selectedMonsterGenesisState,
  selectedMonsterRebornState
} from '../store';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useRecoilValue } from 'recoil';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

export default function MultipleTransferNFT(props) {
  // 数据
  const [approval, setApproval] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState('');
  const account = useRecoilValue(selectedAccountState);
  let needTransferNFT;
  // 条件数据
  const heroInfo = useRecoilValue(heroInfoState);
  const monsterGenesisInfo = useRecoilValue(monsterGenesisInfoState);
  const monsterRebornInfo = useRecoilValue(monsterRebornInfoState);
  const selectedHero = useRecoilValue(selectedHeroState);
  const selectedMonsterGenesis = useRecoilValue(selectedMonsterGenesisState);
  const selectedMonsterReborn = useRecoilValue(selectedMonsterRebornState);
  let nftContract;
  let nftInfo;
  let selectedModel;
  if (props.role === '1') {
    selectedModel = selectedHero;
    nftInfo = heroInfo;
    nftContract = heroContract;
  } else if (props.role === '2') {
    selectedModel = selectedMonsterGenesis;
    nftInfo = monsterGenesisInfo;
    nftContract = monsterGenesisContract;
  } else if (props.role === '3') {
    selectedModel = selectedMonsterReborn;
    nftInfo = monsterRebornInfo;
    nftContract = monsterRebornContract;
  }
  // 状态
  const [executeAlertOpen, setExecuteAlertOpen] = useState(false);
  const [transferAlertOpen, setTransferAlertOpen] = useState(false);
  // 消息条
  const { enqueueSnackbar } = useSnackbar();

  // 执行检测
  const handleExecuteAlertClose = () => {
    setExecuteAlertOpen(false);
  };

  const showExecuteAlert = () => {
    if (selectedModel.length > needTransferNFT.length) {
      setExecuteAlertOpen(true);
    } else {
      setTransferAlertOpen(true);
    }
  };

  // 转移警告
  const handleTransferAlertClose = () => {
    setTransferAlertOpen(false);
  };

  // 授权检测
  const approveCheck = async() => {
    let res = await nftContract.methods
      .isApprovedForAll(account, monsterHelperExecuteAddress)
      .call();
    console.log(res);
    setApproval(res);
  }

  // 输入框
  const handleChange = (event) => {
    setTextFieldValue(event.target.value);
  };

  // 授权
  const approve = async() => {
    nftContract.methods
      .setApprovalForAll(monsterHelperExecuteAddress, true)
      .send({ from: account })
      .then((receipt) => {
        enqueueSnackbar('交易发送成功', {
          variant: 'success',
        });
        // 重新检测授权
        setApproval(true)
      })
      .catch((error) => {
        if (error.message === 'MetaMask Tx Signature: User denied transaction signature.') {
          enqueueSnackbar('用户拒绝交易签名', {
            variant: 'warning',
          });
        } else if (error.message === '用户取消了操作') {
          enqueueSnackbar('用户取消了操作', {
            variant: 'warning',
          });
        } else if (error.message === 'Transaction was not mined within 50 blocks, please make sure your transaction was properly sent. Be aware that it might still be mined!') {
          enqueueSnackbar('交易未在50个区块内上链,请等待上链', {
            variant: 'warning',
          });
        } else {
          enqueueSnackbar(error.message, {
            variant: 'error',
          });
        }
        console.log(error.message);
      });
  }

  // 转移
  const transferNFT = () => {
    console.log(textFieldValue);
    monsterHelperExecuteContract.methods
      .multipleTransferNFT(props.role, selectedModel, account, textFieldValue)
      .send({ from: account })
      .then((receipt) => {
        enqueueSnackbar('交易发送成功', {
          variant: 'success',
        });

      })
      .catch((error) => {
        if (error.message === 'MetaMask Tx Signature: User denied transaction signature.') {
          enqueueSnackbar('用户拒绝交易签名', {
            variant: 'warning',
          });
        } else if (error.message === '用户取消了操作') {
          enqueueSnackbar('用户取消了操作', {
            variant: 'warning',
          });
        } else if (error.message === 'Transaction was not mined within 50 blocks, please make sure your transaction was properly sent. Be aware that it might still be mined!') {
          enqueueSnackbar('交易未在50个区块内上链,请等待上链', {
            variant: 'warning',
          });
        } else {
          enqueueSnackbar(error.message, {
            variant: 'error',
          });
        }
        console.log(error.message);
      });
  }

  if (account === undefined || selectedModel.length === 0) {
    return (
      <Tooltip title='请先选择所需执行的目标'>
        <div>
          <Button variant='outlined' size='small' disabled sx={{ margin: '0 0 0 8px' }}>
            批量转移
          </Button>
        </div>
      </Tooltip>
    );
  } else {
    // 授权检测
    approveCheck();
    // 转移前置条件筛选
    let canTransfer = [];
    // 筛选已经挖矿的重生怪兽
    if (props.role === '3') {
      for (let i of nftInfo) {
        if (!i['mining']) {
          canTransfer.push(i['id']);
        }
      }
      needTransferNFT = selectedModel.filter((x) => canTransfer.includes(x));
    } else {
      needTransferNFT = selectedModel
    }

    return (
      <div>
        <Button
          variant='outlined'
          size='small'
          onClick={() => {
            showExecuteAlert();
          }}
          sx={{ margin: '0 0 0 8px' }}
        >
          批量转移
        </Button>
        <Dialog open={executeAlertOpen} onClose={handleExecuteAlertClose}>
          <DialogTitle>执行检测</DialogTitle>
          <DialogContent>
            <DialogContentText>
              检测到选择的目标中有{selectedModel.length - needTransferNFT.length}
              个不符合转移的前置条件（怪兽未在挖矿），是否继续提交符合条件的
              {needTransferNFT.length}个目标？
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleExecuteAlertClose}>返回</Button>
            <Button
              autoFocus
              onClick={() => {
                handleExecuteAlertClose();
                setTransferAlertOpen(true);
              }}
            >
              确定
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={transferAlertOpen} onClose={handleTransferAlertClose}>
          <DialogTitle>批量转移</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography
                variant="body1"
                sx={{
                  padding: 0,
                  margin: '8px 0px 8px 0px'
                }}>
                将选择的{needTransferNFT.length}个NFT转移到:
              </Typography>
              <TextField
                label="目的地钱包地址"
                variant="outlined"
                value={textFieldValue}
                onChange={handleChange}
                sx={{
                  width: '430px',
                  margin: '8px 0px 0px 0px'
                }}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {approval
              ? <Button
                  variant='contained'
                  onClick={() => {
                    transferNFT();
                    handleTransferAlertClose();
                  }}
                  sx={{
                    margin: '0px 16px 16px 16px'
                  }}
                >
                  确定
                </Button>
              : <Button
                  variant='contained'
                  onClick={approve}
                  sx={{
                    margin: '0px 16px 16px 16px'
                  }}
                >
                  授权NFT
                </Button>
            }
          </DialogActions>
        </Dialog>
      </div>
      
    );
  }
}
