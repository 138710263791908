import { Route, Routes } from 'react-router-dom'

import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Hero from './pages/Hero'
import MonsterFooter from "./pages/MonsterFooter";
import MonsterGenesis from './pages/MonsterGenesis'
import MonsterHeader from "./pages/MonsterHeader";
import MonsterMain from "./pages/MonsterMain"
import MonsterReborn from './pages/MonsterReborn';
import React from "react";
import { RecoilRoot } from "recoil";
import { SnackbarProvider } from 'notistack';

export default function App() {
  return (
    <RecoilRoot>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <CssBaseline />
        <Container maxWidth="lg">
          <MonsterHeader />
            <Routes>
              <Route path='/' element={<MonsterMain />}>
                <Route index element={<MonsterReborn />} />
                <Route path='MonsterGenesis' element={<MonsterGenesis />} />
                <Route path='Hero' element={<Hero />} />
              </Route>
            </Routes>
          <MonsterFooter />
        </Container>
      </SnackbarProvider>
    </RecoilRoot>
  )
}
