import {
  accountListState,
  chainIdState,
  heroListState,
  monsterGenesisListState,
  monsterRebornInfoState,
  monsterRebornListState,
  selectedAccountState,
} from '../store';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import Button from '@mui/material/Button';
import React from 'react';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3 from 'web3';
import Web3Modal from 'web3modal';
import getHeroList from '../utils/getHeroList';
import getMonsterGenesisList from '../utils/getMonsterGenesisList';
import getMonsterRebornList from '../utils/getMonsterRebornList';
import { useSnackbar } from 'notistack';

// 设置默认的web3
export var web3 = new Web3('https://rpc.ankr.com/fantom');

export default function ConnectWallet() {
  // 数据
  const [chainId, setChainId] = useRecoilState(chainIdState);
  const selectedAccount = useRecoilValue(selectedAccountState);
  const setAccountList = useSetRecoilState(accountListState);
  const setMonsterRebornInfo = useSetRecoilState(monsterRebornInfoState);
  const setMonsterRebornList = useSetRecoilState(monsterRebornListState);
  const setMonsterGenesisList = useSetRecoilState(monsterGenesisListState);
  const setHeroList = useSetRecoilState(heroListState);
  // 消息条
  const { enqueueSnackbar } = useSnackbar();

  // provider选项
  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        rpc: {
          250: 'https://rpc.ftm.tools',
        },
      },
    },
  };
  // 实例化web3Modal
  const web3Modal = new Web3Modal({
    cacheProvider: true,
    providerOptions,
  });

  const onClick = async () => {
    try {
      const provider = await web3Modal.connect();
      // console.log(provider);
      web3.setProvider(provider);
      // console.log(web3);
      let _accountList = await web3.eth.getAccounts();
      let _chainId = await web3.eth.getChainId();
      console.log(_chainId);
      setAccountList(_accountList);
      setChainId(_chainId);
      // 获取账户持有的创世怪兽 重生怪兽 英雄
      let _monsterRebornList = await getMonsterRebornList(_accountList[0]);
      let _monsterGenesisList = await getMonsterGenesisList(_accountList[0]);
      let _heroList = await getHeroList(_accountList[0]);
      setMonsterRebornList(_monsterRebornList);
      setMonsterGenesisList(_monsterGenesisList);
      setHeroList(_heroList);
    } catch (error) {
      console.log(error.message);
      if (error.message === 'User Rejected' || error.message === 'User closed modal') {
        enqueueSnackbar('用户取消了操作', {
          variant: 'warning',
        });
      } else {
        if (error !== 'Modal closed by user') {
          enqueueSnackbar(error.message, {
            variant: 'error',
          });
          console.log(error);
        }
      }
    }
  };

  const disconnect = async () => {
    console.log(web3._provider);
    if ('disconnect' in web3._provider) {
      console.log('a');
      web3._provider.disconnect();
    }
    web3Modal.clearCachedProvider();
    // 恢复初始web3
    web3 = new Web3('https://rpc.fantom.network/');
    console.log(web3._provider)
    // 清除账户数据
    setAccountList([]);
    setChainId(0);
    setMonsterGenesisList([]);
    setMonsterRebornList([]);
    setMonsterRebornInfo([]);
    setHeroList([]);
  };

  // console.log(chainId)
  // console.log(selectedAccount)

  if (selectedAccount === undefined) {
    return (
      <Button
        variant='contained'
        onClick={onClick}
        disableElevation
        size='large'
        sx={{
          margin: '0 0 0 8px',
          width: '150px'
        }}
      >
        连接钱包
      </Button>
    );
  } else if (chainId !== 250) {
    return (
      <Button
        variant='contained'
        onClick={onClick}
        disableElevation
        size='large'
        color='error'
        sx={{
          margin: '0 0 0 8px',
          width: '150px'
        }}
      >
        网络错误
      </Button>
    );
  } else {
    return (
      <Button
        variant='contained'
        onClick={disconnect}
        disableElevation
        size='large'
        sx={{
          margin: '0 0 0 8px',
          width: '150px'
        }}
      >
        {selectedAccount.slice(0, 5) + '...' + selectedAccount.slice(-4)}
      </Button>
    );
  }
}
