import { MonsterGenesisInfoContract } from './web3Contract'
import cutArray from './cutArray';

const handleMonsterGenesisInfo = (monsterGenesisUintInfo, monsterGenesisStringInfo, monsterGenesisBoolInfo, monsterGenesisListLength, setMonsterGenesisInfo) => {
  let monsterGenesisInfo = [];
  let monsterGenesisType = [
    '盗贼', '刽子手', '守卫', '猎人', '术士', '召唤师', '战士',
    '恶魔', '信徒', '萨满', '弓箭手', '投掷者', '不朽者', '泰坦', '黑暗骑士'
  ]
  for (let i = 0; i < monsterGenesisListLength; ++i) {
    //  处理孵化间隔
    let timeZoneOffset = - new Date().getTimezoneOffset() * 60 * 1000;
    let feedInterval = 0 * 24 * 60 * 60 * 1000;
    let feedTime = 0;
    if (monsterGenesisUintInfo[i][11] !== '0') {
      feedTime = monsterGenesisUintInfo[i][11] * 1000 + timeZoneOffset + feedInterval
    }
    monsterGenesisInfo.push({
      id: monsterGenesisUintInfo[i][0],
      name: monsterGenesisStringInfo[i][0] + ' ' + monsterGenesisStringInfo[i][1],
      type: monsterGenesisType[monsterGenesisUintInfo[i][1] - 1],
      mainAttribute: monsterGenesisUintInfo[i][15],
      lastFeed: monsterGenesisUintInfo[i][11],
      feedCycle: monsterGenesisUintInfo[i][12],
      feedTime: feedTime,
      feedable: monsterGenesisBoolInfo[i][0],
      generation: monsterGenesisUintInfo[i][14],
      rarity: monsterGenesisUintInfo[i][15],
      copper: monsterGenesisUintInfo[i][17],
      oldCopper: monsterGenesisUintInfo[i][16] / 10 ** 18,
      meat: monsterGenesisUintInfo[i][18],
      consumption: monsterGenesisUintInfo[i][13],
      meatConsumption: `${monsterGenesisUintInfo[i][18]} / ${monsterGenesisUintInfo[i][13]}`,
      isWithdrawable: monsterGenesisBoolInfo[i][1],
    });
  }
  setMonsterGenesisInfo(monsterGenesisInfo)
};

const getMonsterGenesisInfo = async (monsterGenesisList, setMonsterGenesisInfo, setMonsterGenesisInfoLoading, setMonsterGenesisLoadingProgress) => {
  setMonsterGenesisInfoLoading(true);
  setMonsterGenesisLoadingProgress(0)
  let uintInfo = [], stringInfo = [], boolInfo = [];
  // 分割原数组
  let monsterGenesisListCut = cutArray(monsterGenesisList, 20);
  // 查询链上信息
  for (let i = 0; i < monsterGenesisListCut.length; i++) {
    let res = await MonsterGenesisInfoContract.methods
      .getMonsterGenesisInfo(monsterGenesisListCut[i])
      .call();
    uintInfo = [...uintInfo, ...cutArray(res['0'], 19)];
    stringInfo = [...stringInfo, ...cutArray(res['1'], 2)];
    boolInfo = [...boolInfo, ...cutArray(res['2'], 2)];
    setMonsterGenesisLoadingProgress((90 / monsterGenesisListCut.length) * (i + 1));
  }
  setMonsterGenesisLoadingProgress(90);
  handleMonsterGenesisInfo(uintInfo, stringInfo, boolInfo, monsterGenesisList.length, setMonsterGenesisInfo);
  setMonsterGenesisLoadingProgress(100);
  setMonsterGenesisInfoLoading(false);
};

export default getMonsterGenesisInfo;
