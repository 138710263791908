import {
  monsterRebornInfoLoadingState,
  monsterRebornInfoState,
  monsterRebornListState,
  monsterRebornLoadingProgressState,
  selectedAccountState,
} from '../store';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import Button from '@mui/material/Button';
import axios from 'axios';
import getMonsterRebornInfo from '../utils/getMonsterRebornInfo';
import { mineContract } from '../utils/web3Contract';
import { useSnackbar } from 'notistack';
import { web3 } from './ConnectWallet';

export const stakeMonsterReborn = async (
  roleIndex,
  tokenId,
  account,
  monsterRebornList,
  setMonsterRebornInfo,
  setMonsterRebornInfoLoading,
  setMonsterRebornLoadingProgress,
  enqueueSnackbar,
) => {
  mineContract.methods
    .stake(roleIndex, tokenId)
    .send({ from: account, gasPrice: await web3.eth.getGasPrice() })
    .then((receipt) => {
      enqueueSnackbar('交易发送成功', {
        variant: 'success',
      });
      // 刷新重生怪兽数据
      getMonsterRebornInfo(
        account,
        monsterRebornList,
        setMonsterRebornInfo,
        setMonsterRebornInfoLoading,
        setMonsterRebornLoadingProgress
      );
    })
    .catch((error) => {
      if (error.message === 'MetaMask Tx Signature: User denied transaction signature.') {
        enqueueSnackbar('用户拒绝交易签名', {
          variant: 'warning',
        });
      } else if (error.message === '用户取消了操作') {
        enqueueSnackbar('用户取消了操作', {
          variant: 'warning',
        });
      } else if (error.message === 'Transaction was not mined within 50 blocks, please make sure your transaction was properly sent. Be aware that it might still be mined!') {
        enqueueSnackbar('交易未在50个区块内上链,请等待上链', {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      }
      console.log(error.message);
    });
};

export const withdrawalMonsterReborn = async (
  roleIndex,
  tokenId,
  account,
  monsterRebornList,
  setMonsterRebornInfo,
  setMonsterRebornInfoLoading,
  setMonsterRebornLoadingProgress,
  enqueueSnackbar,
) => {
  let bl = await web3.eth.getBlock('latest')
  mineContract.methods
    .withdrawal(roleIndex, tokenId)
    .send({ from: account, gasPrice: await web3.eth.getGasPrice() })
    .then((receipt) => {
      enqueueSnackbar('交易发送成功', {
        variant: 'success',
      });
      // 刷新重生怪兽数据
      getMonsterRebornInfo(
        account,
        monsterRebornList,
        setMonsterRebornInfo,
        setMonsterRebornInfoLoading,
        setMonsterRebornLoadingProgress
      );
    })
    .catch((error) => {
      if (error.message === 'MetaMask Tx Signature: User denied transaction signature.') {
        enqueueSnackbar('用户拒绝交易签名', {
          variant: 'warning',
        });
      } else if (error.message === '用户取消了操作') {
        enqueueSnackbar('用户取消了操作', {
          variant: 'warning',
        });
      } else if (error.message === 'Transaction was not mined within 50 blocks, please make sure your transaction was properly sent. Be aware that it might still be mined!') {
        enqueueSnackbar('交易未在50个区块内上链,请等待上链', {
          variant: 'warning',
        });
      } else {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      }
      console.log(error.message);
    });
};

export function StakeMonsterReborn(props) {
  // 数据
  const account = useRecoilValue(selectedAccountState);
  const monsterRebornList = useRecoilValue(monsterRebornListState);
  const setMonsterRebornInfo = useSetRecoilState(monsterRebornInfoState);
  // 状态
  const setMonsterRebornInfoLoading = useSetRecoilState(monsterRebornInfoLoadingState);
  const setMonsterRebornLoadingProgress = useSetRecoilState(monsterRebornLoadingProgressState);
  // 消息条
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Button
      id={props.id}
      variant='contained'
      size='small'
      color='warning'
      onClick={() => {
        console.log('a');
        stakeMonsterReborn(
          3,
          props.id,
          account,
          monsterRebornList,
          setMonsterRebornInfo,
          setMonsterRebornInfoLoading,
          setMonsterRebornLoadingProgress,
          enqueueSnackbar
        );
      }}
      disableElevation
    >
      挖矿
    </Button>
  );
}

export function WithdrawalMonsterReborn(props) {
  // 数据
  const account = useRecoilValue(selectedAccountState);
  const monsterRebornList = useRecoilValue(monsterRebornListState);
  const setMonsterRebornInfo = useSetRecoilState(monsterRebornInfoState);
  // 状态
  const setMonsterRebornInfoLoading = useSetRecoilState(monsterRebornInfoLoadingState);
  const setMonsterRebornLoadingProgress = useSetRecoilState(monsterRebornLoadingProgressState);
  // 消息条
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Button
      id={props.id}
      variant='contained'
      size='small'
      color='success'
      onClick={() => {
        console.log(account);
        withdrawalMonsterReborn(
          3,
          props.id,
          account,
          monsterRebornList,
          setMonsterRebornInfo,
          setMonsterRebornInfoLoading,
          setMonsterRebornLoadingProgress,
          enqueueSnackbar
        );
      }}
      disableElevation
    >
      收矿
    </Button>
  );
}
