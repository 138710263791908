import { DataGrid, GridOverlay, zhCN } from '@mui/x-data-grid';
import { fullWidthDisplayState, monsterGenesisInfoLoadingState, monsterGenesisInfoState, monsterGenesisLoadingProgressState, selectedMonsterGenesisState } from "../store";
import { useRecoilState, useRecoilValue } from "recoil";

import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import React from "react";
import StarIcon from '@mui/icons-material/Star';

function CustomLoadingOverlay() {
  const monsterGenesisLoadingProgress = useRecoilValue(monsterGenesisLoadingProgressState)
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress variant="determinate" value={monsterGenesisLoadingProgress} />
      </div>
    </GridOverlay>
  )
}

export default function MonsterGenesisList() {
  // 数据
  const monsterGenesisInfo= useRecoilValue(monsterGenesisInfoState)
  const monsterGenesisInfoLoading = useRecoilValue(monsterGenesisInfoLoadingState)
  // 状态
  const [selectedGenesisReborn, setSelectedMonsterGenesisState] = useRecoilState(selectedMonsterGenesisState)
  const fullWidthDisplay = useRecoilValue(fullWidthDisplayState);
  //  定义列选项
  const columns = [
    {
      type: 'number',
      field: 'id',
      headerName: '怪兽 ID',
      width: 75,
      headerAlign: 'center',
      align: 'center',
    },
    {
      type: 'string',
      field: 'name',
      headerName: '名称',
      width: 215,
      headerAlign: 'center',
      align: 'center',
      hide: !fullWidthDisplay,
    },
    {
      type: 'string',
      field: 'type',
      headerName: '种族',
      width: 80,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'mainAttribute',
      headerName: '主属性',
      width: 75,
      headerAlign: 'center',
      align: 'center',
    },
    {
      type: "dateTime",
      field: 'feedTime',
      headerName: '怪兽下次可喂食时间',
      width: 155,
      headerAlign: 'center',
      align: 'center',
      valueFormatter: (params) => {
        if (params.value === 0) {
          return '未喂食过';
        } else {
          let date = new Date(params.value);
          return date.toJSON().substr(0, 19).replace('T', ' ');
        }
      },
    },
    {
      type: 'number',
      field: 'generation',
      headerName: '下蛋数',
      width: 75,
      headerAlign: 'center',
      align: 'center',
    },
    {
      type: 'number',
      field: 'copper',
      headerName: '铜币',
      width: 80,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'oldCopper',
      headerName: '旧铜币',
      width: 80,
      headerAlign: 'center',
      align: 'center',
      hide: !fullWidthDisplay,
      renderCell: (params) => {
        // 移除已迁移的旧铜币数量
        if (params.row.isWithdrawable) {
          return params.row.oldCopper;
        } else {
          return 0;
        }
      }
    },
    {
      type: 'string',
      field: 'meatConsumption',
      headerName: '肉',
      width: 80,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'rarity',
      headerName: "稀有度",
      width: 80,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        let icon = '';
        // console.log(params)
        if (params.value >= 24) {
          icon = <div><StarIcon fontSize="small"/><StarIcon fontSize="small"/><StarIcon fontSize="small"/><StarIcon fontSize="small"/></div>
        } else if (params.value >= 19 && params.value <= 23) {
          icon = <div><StarIcon fontSize="small"/><StarIcon fontSize="small"/><StarIcon fontSize="small"/></div>
        } else if (params.value >= 13 && params.value <= 18) {
          icon = <div><StarIcon fontSize="small"/><StarIcon fontSize="small"/></div>
        } else if (params.value <= 12) {
          icon = <div><StarIcon fontSize="small"/></div>
        }
        return (
          <IconButton
            variant="contained"
            disable
            disableRipple
            size="small"
            sx={{
              color: "rgb(255, 204, 0)",
              '&:hover': {
                backgroundColor: 'transparent',
              }
            }}>{icon}</IconButton>
        );
      }
    },
    {
      field: 'action',
      headerName: "操作",
      width: 80,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        let timestamp = Date.parse(new Date())/1000;
        let button = ''
        if (!params.row.feedable) {
          button = <Button variant="contained" size="small" color="success" disableElevation>下蛋</Button>
        } else if (timestamp >= params.row.feedTime && params.row.meat >= params.row.consumption) {
          button = <Button variant="contained" size="small" color="success" disableElevation>喂食</Button>
        } else if (params.row.meat < params.row.consumption && params.row.copper >= (params.row.consumption - params.row.meat) * 12) {
          button = <Button variant="contained" size="small" color="success" disableElevation>买肉</Button>
        } else if (params.row.copper < (params.row.consumption - params.row.meat) * 12) {
          button = <Button variant="contained" size="small" color="success" disableElevation>买铜</Button>
        } else {
          button = <Button variant="contained" size="small" color="success" disableElevation>其他</Button>
        }
        return (
          <span>{button}</span>
        );
      }
    },
  ]

  return (
      <Box sx={{ width: "100%", height: "1150px", margin: "8px 0px 8px 0px", textAlign: "center"}}> 
        <DataGrid
          rows={monsterGenesisInfo}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[5]}
          checkboxSelection
          disableSelectionOnClick
          components={{
            LoadingOverlay: CustomLoadingOverlay,
          }}
          loading={monsterGenesisInfoLoading}
          // 处理选中的项目
          onSelectionModelChange={
            (newSelectionModel) => {
              setSelectedMonsterGenesisState(newSelectionModel);
              console.log(newSelectionModel)
            }
          }
          selectionModel={selectedGenesisReborn}
          localeText={zhCN.components.MuiDataGrid.defaultProps.localeText}
          align="center"
        />
      </Box>
  )
}