import {
  dataAnalysisDisplayState,
  fullWidthDisplayState,
  heroInfoLoadingState,
  heroInfoState,
  heroListState,
  heroLoadingProgressState,
  mainGridState,
} from "../store";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import HeroList from "../components/HeroList";
import IconButton from '@mui/material/IconButton';
import LoadHero from '../components/LoadHero';
import MultipleAdventure from "../components/Hero/MultipleAdventure/MultipleAdventure";
import MultipleTransferNFT from '../components/MultipleTransferNFT';
import React from "react";
import Tooltip from '@mui/material/Tooltip';
import Typography from "@mui/material/Typography";
import getHeroInfo from '../utils/getHeroInfo';

export default function MonsterGenesis() {
  const [dataAnalysisDisplay, setDataAnalysisDisplay] = useRecoilState(dataAnalysisDisplayState);
  const [fullWidthDisplay, setFullWidthDisplay] = useRecoilState(fullWidthDisplayState);
  const [mainGrid, setMainGrid] = useRecoilState(mainGridState);
  const [heroInfo, setHeroInfo] = useRecoilState(heroInfoState);
  const heroList = useRecoilValue(heroListState);
  const setHeroInfoLoading = useSetRecoilState(heroInfoLoadingState);
  const setHeroLoadingProgress = useSetRecoilState(heroLoadingProgressState)

  const toggleDataAnalysisDisplay = () => {
    if (fullWidthDisplay === false) {
      setDataAnalysisDisplay('none');
      setMainGrid(12);
      setFullWidthDisplay(true);
    } else {
      setDataAnalysisDisplay('block');
      setMainGrid(9);
      setFullWidthDisplay(false);
    }
  };

  // 初始化页面
  if (heroList.length > 0 && heroInfo.length === 0) {
    getHeroInfo(
      heroList,
      setHeroInfo,
      setHeroInfoLoading,
      setHeroLoadingProgress
    );
  }

  return (
    <Box sx={{ padding: "8px 8px 8px 8px" }}>
      <Box sx={{ position: 'relative', padding: "48px 0 32px 0" }}>
        <IconButton
          variant="outlined"
          size="small"
          onClick={toggleDataAnalysisDisplay}
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            margin: "0 0 0 8px",
          }}
        >
          {fullWidthDisplay ? <ArrowBackIcon /> : <ArrowForwardIcon />}
        </IconButton>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          component="div"
          sx={{ margin: "auto" }}
        >
          英雄
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          pt: 2,
          padding: "8px 0 8px 0",
        }}
      >
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          variant="outlined"
          size="small"
          href="https://monster.metaland.game/monster-genesis"
          target="_blank"
          sx={{ margin: "0 0 0 8px" }}
        >
          进入官网
        </Button>
        <MultipleTransferNFT role='1' />
        <MultipleAdventure />
        <LoadHero />
      </Box>
      <HeroList />
    </Box>
  );
}