import {
  monsterRebornInfoLoadingState,
  monsterRebornInfoState,
  monsterRebornListState,
  monsterRebornLoadingProgressState,
  selectedAccountState,
} from '../store';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import getMonsterRebornInfo from '../utils/getMonsterRebornInfo';

export default function LoadMonsterReborn() {
  // 数据
  const selectedAccount = useRecoilValue(selectedAccountState);
  const monsterRebornList = useRecoilValue(monsterRebornListState);
  const setMonsterRebornInfo = useSetRecoilState(monsterRebornInfoState);
  // 状态
  const setMonsterRebornInfoLoading = useSetRecoilState(monsterRebornInfoLoadingState);
  const setMonsterRebornLoadingProgress = useSetRecoilState(monsterRebornLoadingProgressState);

  if (selectedAccount === undefined && monsterRebornList.length === 0) {
    return (
      <Tooltip title='请先链接钱包'>
        <div>
          <Button variant='outlined' size='small' disabled sx={{ margin: '0 0 0 8px' }}>
            刷新数据
          </Button>
        </div>
      </Tooltip>
    );
  } else {
    return (
      <Button
        variant='outlined'
        size='small'
        onClick={() => {
          console.log(monsterRebornList);
          getMonsterRebornInfo(
            selectedAccount,
            monsterRebornList,
            setMonsterRebornInfo,
            setMonsterRebornInfoLoading,
            setMonsterRebornLoadingProgress
          );
        }}
        sx={{ margin: '0 0 0 8px' }}
      >
        刷新数据
      </Button>
    );
  }
}
