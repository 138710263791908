import { atom, selector } from "recoil";

// import Web3 from "web3";

// web3
export const accountListState = atom({
  key: 'accountListState',
  default: [],
});

export const selectedAccountState = selector({
  key: 'selectedAccountState',
  // default: '',
  get: ({get}) => {
    if (get(accountListState) !== []) {
      return get(accountListState)[0]
    } else {
      return ''
    }
  }
});

export const chainIdState = atom({
  key: 'chainIdState',
  default: 0,
});

export const summonersState = atom({
  key: 'summonersState',
  default: [],
});

export const monkInfoState = atom({
  key: 'monkInfoState',
  default: [],
});

export const nowGasPriceState = atom({
  key: 'nowGasPriceState',
  default: 0,
});

export const selectionModelState = atom({
  key: "selectionModelState",
  default: [],
});

export const approvedState = atom({
  key: 'approvedState',
  default: false,
});

export const gaslimitState = atom({
  key: 'gaslimitState',
  default: true,
});

export const alertDialogState = atom({
  key: 'alertDialogState',
  default: false,
});

export const totalAdventureState = atom({
  key: 'totalAdventureState',
  default: 0,
});

export const totalWinsState = atom({
  key: 'totalWinsState',
  default: 0,
});

export const totalCopperWorldState = atom({
  key: 'totalCopperWorldState',
  default: 0,
});

export const totalCopperWorldOfSummonerState = atom({
  key: 'totalCopperWorldOfSummonerState',
  default: 0,
});

export const infoLoadingState = atom({
  key: 'infoLoadingState',
  default: false,
});

// 创世怪兽
export const monsterGenesisListState = atom({
  key: 'monsterGenesisListState',
  default: [],
});

export const monsterGenesisInfoState = atom({
  key: 'monsterGenesisInfoState',
  default: [],
});

export const selectedMonsterGenesisState = atom({
  key: 'selectedMonsterGenesisState',
  default: [],
});

export const monsterGenesisInfoLoadingState = atom({
  key: 'monsterGenesisInfoLoadingState',
  default: false,
});

export const monsterGenesisLoadingProgressState = atom({
  key: 'monsterGenesisLoadingProgressState',
  default: 0,
});

// 重生怪兽
export const monsterRebornListState = atom({
  key: 'monsterRebornListState',
  default: [],
});

export const monsterRebornInfoState = atom({
  key: 'monsterRebornInfoState',
  default: [],
});

export const selectedMonsterRebornState = atom({
  key: 'selectedMonsterRebornState',
  default: [],
});

export const monsterRebornInfoLoadingState = atom({
  key: 'monsterRebornInfoLoadingState',
  default: false,
});

export const monsterRebornLoadingProgressState = atom({
  key: 'monsterRebornLoadingProgressState',
  default: 0,
});

// 英雄
export const heroListState = atom({
  key: 'heroListState',
  default: [],
});

export const heroInfoState = atom({
  key: 'heroInfoState',
  default: [],
});

export const selectedHeroState = atom({
  key: 'selectedHeroState',
  default: [],
});

export const heroInfoLoadingState = atom({
  key: 'heroInfoLoadingState',
  default: false,
});

export const heroLoadingProgressState = atom({
  key: 'heroLoadingProgressState',
  default: 0,
});

// 数据分析
export const dataAnalysisDisplayState = atom({
  key: 'dataAnalysisDisplayState',
  default: 'block',
});

// 主界面
export const mainGridState = atom({
  key: 'mainGridState',
  default: 9,
});

export const fullWidthDisplayState = atom({
  key: 'fullWidthDisplayState',
  default: false,
});


// 数据统计
export const monsterGenesisCountState = selector({
  key: 'monsterGenesisCountState',
  default: 0,
  get: ({get}) => {
    return get(monsterGenesisListState).length
  }
});

export const monsterRebornCountState = selector({
  key: 'monsterRebornCountState',
  default: 0,
  get: ({get}) => {
    return get(monsterRebornListState).length
  }
});

export const heroCountState = selector({
  key: 'heroCountState',
  default: 0,
  get: ({get}) => {
    return get(heroListState).length
  }
});

export const monsterGenesisCopperState = selector({
  key: 'monsterGenesisCopperState',
  default: 0,
  get: ({get}) => {
    let monsterGenesisCopper = 0;
    let monsterGenesisInfo = get(monsterGenesisInfoState);
    if (monsterGenesisInfo.length > 0) {
      for (let i of monsterGenesisInfo) {
        monsterGenesisCopper = monsterGenesisCopper + parseInt(i['copper']);
      }
    }
    return monsterGenesisCopper
  }
});

export const monsterRebornCopperState = selector({
  key: 'monsterRebornCopperState',
  default: 0,
  get: ({get}) => {
    let monsterRebornCopper = 0;
    let monsterRebornInfo = get(monsterRebornInfoState);
    if (monsterRebornInfo.length > 0) {
      for (let i of monsterRebornInfo) {
        monsterRebornCopper = monsterRebornCopper + parseInt(i['copper']);
      }
    }
    return monsterRebornCopper
  }
});
