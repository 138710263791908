import { dataAnalysisDisplayState, mainGridState } from '../store';

import DataAnalysis from './DataAnalysis';
import Grid from '@mui/material/Grid';
import { Outlet } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import React from 'react';
import { useRecoilValue } from 'recoil';

export default function MonsterMain() {
  const mainGrid = useRecoilValue(mainGridState);
  const dataAnalysisDisplay = useRecoilValue(dataAnalysisDisplayState);

  return (
    <Grid container spacing={4}>
      <Grid
        item
        xs={12}
        sm={mainGrid}
      >
        <Paper
          elevation={0}
          variant='outlined'
          sx={{
            padding: '0 0 16px 0',
            backgroundColor: 'rgb(250, 252, 255)',
            borderRadius: '6px',
          }}
        >
          <Outlet />
        </Paper>
      </Grid>

      <Grid item xs={12} sm={3} sx={{ display: dataAnalysisDisplay }}>
        <Paper
          elevation={0}
          variant='outlined'
          sx={{
            padding: '0 0 48px 0',
            backgroundColor: 'rgb(250, 252, 255)',
            borderRadius: '6px',
          }}
        >
          <DataAnalysis />
        </Paper>
      </Grid>
    </Grid>
  );
}
