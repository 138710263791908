import {
  monsterGenesisInfoLoadingState,
  monsterGenesisInfoState,
  monsterGenesisListState,
  monsterGenesisLoadingProgressState,
  selectedAccountState,
} from '../store';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import getMonsterGenesisInfo from '../utils/getMonsterGenesisInfo';

export default function LoadMonsterReborn() {
  // 数据
  const selectedAccount = useRecoilValue(selectedAccountState);
  const monsterGenesisList = useRecoilValue(monsterGenesisListState);
  const setMonsterGenesisInfo = useSetRecoilState(monsterGenesisInfoState);
  // 状态
  const setMonsterGenesisInfoLoading = useSetRecoilState(monsterGenesisInfoLoadingState);
  const setMonsterGenesisLoadingProgress = useSetRecoilState(monsterGenesisLoadingProgressState);

  if (selectedAccount === undefined && monsterGenesisList.length === 0) {
    return (
      <Tooltip title='请先链接钱包'>
        <div>
          <Button variant='outlined' size='small' disabled sx={{ margin: '0 0 0 8px' }}>
            刷新数据
          </Button>
        </div>
      </Tooltip>
    );
  } else {
    return (
      <Button
        variant='outlined'
        size='small'
        onClick={() => {
          console.log(monsterGenesisList);
          getMonsterGenesisInfo(
            monsterGenesisList,
            setMonsterGenesisInfo,
            setMonsterGenesisInfoLoading,
            setMonsterGenesisLoadingProgress
          );
        }}
        sx={{ margin: '0 0 0 8px' }}
      >
        刷新数据
      </Button>
    );
  }
}
