import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import React from "react";
import Typography from '@mui/material/Typography';

export default function MonsterFooter() {
  return (
    <Box sx={{ flexGrow: 1, padding: " 48px 0 48px 0" }}>
      <Typography variant="overline" component="div" color="primary.main" sx={{ flexGrow: 1 }}>
        网站设计与合约编写：YYP
      </Typography>
      {/* <Typography variant="overline" component="div" color="primary.main" sx={{ flexGrow: 1 }}>
        智能合约：
        <Link href="https://ftmscan.com/address/0x6885f4c2343e282f1db86910488ceF28067bD3f4" target="_blank">MonkFirstAdventureInfo</Link>（数据查询）<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <Link href="https://ftmscan.com/address/0xc0C9732799501B9d86a43F979DDf4034d77CE83d" target="_blank">MonsterHelper</Link>（任务执行）
      </Typography> */}
    </Box>
  );
}